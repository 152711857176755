import React, { useState, useRef, useEffect } from "react"
import Col, { ColProps } from "../Col/Col";
import Row, { RowProps } from "../Row/Row";
import "./Reviews.scss"
import Review from "../Review/Review";
import Title from '@/components/Title/Title';
import Button from "../Button/Button";

import review1 from "../../img/review1.jpg"
import review2 from "../../img/review2.jpg"
import review3 from "../../img/review3.jpg"
import review4 from "../../img/review4.jpg"
import review5 from "../../img/review5.jpg"
import review6 from "../../img/review6.jpg"

import arrowRight from "../../img/arrowRight.svg"
import arrowLeft from "../../img/arrowLeft.svg"

interface ReviewsProps {
  className?: string
  id?: string
  name?: string
  text?: string
  image?: string
}

const Reviews = ({
  className,
  id,
  name,
  text,
  image
} : ReviewsProps) => {

const [isFirst, setIsFirst] = useState(true)
const [isSecond, setIsSecond] = useState(false)
const [isThird, setIsThird] = useState(false)
const [isFourth, setIsFourth] = useState(false)
const [isFifth, setIsFifth] = useState(false)
const [isSixth, setIsSixth] = useState(false)

/* HANDLE TOGGLE TRIPPLES */

const handleFirst = () => {
  setIsFirst(true)
  setIsSecond(false)
  setIsThird(false)
  setIsFourth(false)
  setIsFifth(false)
  setIsSixth(false)
}
const handleSecond = () => {
  setIsFirst(false)
  setIsSecond(true)
  setIsThird(false)
  setIsFourth(false)
  setIsFifth(false)
  setIsSixth(false)
}
const handleThird = () => {
  setIsFirst(false)
  setIsSecond(false)
  setIsThird(true)
  setIsFourth(false)
  setIsFifth(false)
  setIsSixth(false)
}

const handleFourth = () => {
  setIsFirst(false)
  setIsSecond(false)
  setIsThird(false)
  setIsFourth(true)
  setIsFifth(false)
  setIsSixth(false)
}

const handleFifth = () => {
  setIsFirst(false)
  setIsSecond(false)
  setIsThird(false)
  setIsFourth(false)
  setIsFifth(true)
  setIsSixth(false)
}

const handleSixth = () => {
  setIsFirst(false)
  setIsSecond(false)
  setIsThird(false)
  setIsFourth(false)
  setIsFifth(false)
  setIsSixth(true)
}

return (

    <Row className="reviews__wrap">
      <div className="reviews__arrows">
        {/*<img className="reviews__arrow" src={arrowLeft} onClick={isFirst? handleThird : handleFirst} />
        <img className="reviews__arrow" src={arrowRight} />
        */}
      </div>

      <Review
        id="review__1"
        link="https://vimeo.com/user107734185/review/609028016/47f22abc75"
        image={review1}
        className={isFirst ? "active" : ""}
        name="Enver Mehmet"
        text="I feel more abundant & my relationships are now more fulfilling. This program will serve me for the rest of my life."
      />
      <Review
        id="review__2"
        link="https://vimeo.com/user107734185/review/707764108/66d468c5ea"
        image={review2}
        className={isSecond ? "active" : ""}
        name="Craig Stewart"
        text="Jon & Vash always help me discover the root cause of problems and help me overcome them."
      />
      <Review
        id="review__3"
        link="https://vimeo.com/user107734185/review/710762777/751aba771b"
        image={review3}
        className={isThird ? "active" : ""}
        name="Tyrelle Anderson-Brown"
        text="Before starting with Jon, it was tough, I wasn't making much money, I didn't have any routines or systems."
        link=""/>

      <Review
        id="review__4"
        link="https://vimeo.com/user107734185/review/650549212/8b1c4c2f29"
        image={review4}
        className={isFourth ? "active" : ""}
        name="Mac Magyaros"
        text="Hands down the most valuable program and the best decision I’ve made as an entrepreneur."
      />
      <Review
        id="review__5"
        link="https://vimeo.com/user107734185/review/636168183/ce5eb990bc"
        image={review5}
        className={isFifth ? "active" : ""}
        name="Deyan Babic"
        text="Jon & Vash helped me so much to achieve my goal of living life of my dreams."
      />
      <Review
        id="review__6"
        link="https://vimeo.com/user107734185/review/710824344/53659d6006"
        image={review6}
        className={isSixth ? "active" : ""}
        name="Xabiso Mdluli"
        text="Because of Mindtrepreneur, I became not only a better entrepreneur, but also a better person - somebody I am proud of."
      />

      <div className="reviews__dots">
        <div onClick={handleFirst} id="firstDot" className={`reviews__dot ${isFirst ? "reviews__dot-active" : ""}`} ></div>
        <div className={`reviews__dot ${isSecond ? "reviews__dot-active" : ""}`} onClick={handleSecond} id="secondDot"></div>
        <div className={`reviews__dot ${isThird ? "reviews__dot-active" : ""}`} onClick={handleThird} id="thirdDot"></div>
        <div className={`reviews__dot ${isFourth ? "reviews__dot-active" : ""}`} onClick={handleFourth} id="fourthDot"></div>
        <div className={`reviews__dot ${isFifth ? "reviews__dot-active" : ""}`} onClick={handleFifth} id="fifthDot"></div>
        <div className={`reviews__dot ${isSixth ? "reviews__dot-active" : ""}`} onClick={handleSixth} id="sixthDot"></div>
      </div>

      <Button type="terciary" link="/testimonials" text="See all testimonials" />

    </Row>
  )
};

export default Reviews;
