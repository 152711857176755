import React, { ReactChild, ReactChildren } from "react";
import { Link } from "gatsby"
import Section, { SectionProps } from "../Section/Section";
import Row, { RowProps } from "../Row/Row";
import Col, { ColProps } from "../Col/Col";
import "./CtaBar.scss"

interface CtaBarProps {
  className?: string
  id?: string
  title?: string
  link?: string
  linkText?: string
}

const CtaBar = ({
  className,
  id,
  title,
  link,
  linkText
} : CtaBarProps) => {

  return (
    <Section className="CtaBar">

      <Row className="CtaBar__row">
        <h4>{title}</h4>
        <Link className="button primary" to={link}>{linkText}</Link>
      </Row>

    </Section>
  )
};

export default CtaBar;
